//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 订单查询
import findVoidOrderDetail from "@/lib/data-service/flight/fligthInternationalAdmin/flight_inter_buyer_manage_order_findVoidOrderDetail.js";
import scrollTop from '@/lib/common-service/scrollTop'
import findRefundOrderDetail from "@/lib/data-service/flight/fligthInternationalAdmin/flight_inter_buyer_manage_order_findRefundOrderDetail.js";
import findChangeOrder from "@/lib/data-service/flight/fligthInternationalAdmin/findChangeOrderDetail";
export default {
  data() {
    return {
      detailData: null,
      orderNo: "",
      passengers: [],
      type:'',
      remark:''
    };
  },
  methods: {
    getDetail(){
      console.time("time1")
      let type = this.$route.query.type
      if(type=='changPay'){
          findVoidOrderDetail({ invalId : this.orderNo }).then(res => {
            this.detailData = res.data;
            this.$forceUpdate();
        })
      }
      else if(type=='Changing'){
        findChangeOrder({chaOrderId:this.orderNo}).then(res=>{
            this.detailData = res.data
            this.newRouteList = res.data.passengers[0].newRouteList
            this.oldRouteList =  res.data.passengers[0].oldRouteList
            console.log(res.data.passengers)
        })
      }
      else{
          findRefundOrderDetail({ refundOrderNo:this.orderNo }).then(res=>{
               this.detailData = res.data;
       })
      }
      console.timeEnd("time1")
    },
  },
  created() {
    if (this.$route.query.ordersNo) {
      this.orderNo = this.$route.query.ordersNo
      this.type = this.$route.query.type
      this.getDetail()
    }
  }
};
